import type { AsyncDataOptions } from "#app";

export default async function useProtectedData<
  DataT,
  DataE = Error,
  ResT = DataT
>(key: string, path: string, options: AsyncDataOptions<ResT, DataT> = {}) {
  const {
    public: { apiUrl },
  } = useRuntimeConfig();

  const token = useToken();

  const {
    data,
    error,
    pending: loading,
    refresh,
  } = await useAsyncData<ResT, DataE, DataT>(
    key,
    () =>
      $fetch<ResT>(`${apiUrl}${path}`, {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }),
    {
      ...options,
      watch: (options.watch || []).concat(token),
    }
  );

  return {
    data,
    error,
    loading,
    refresh,
  };
}
