export const COLORS = {
  black100: "#707070",
  black: "#000000",
  white: "#ffffff",
  redLight: "#F4756E",
  redMedium: "#F22F25",
  redDark: "#B8231C",
  pinkLight: "#E8689B",
  pinkMedium: "#E42270",
  pinkDark: "#B01A56",
  orangeLight: "#FFB45E",
  orangeMedium: "#FE9013",
  orangeDark: "#D25800",
  yellowLight: "#FFE278",
  yellowMedium: "#FFD02C",
  yellowDark: "#CDA000",
  greenLight: "#5BBA7B",
  greenMedium: "#1B9042",
  greenDark: "#115C2A",
  blueLight: "#0E7EC8",
  blueMedium: "#006DB5",
  blueDark: "#004E82",
  purpleLight: "#A585D6",
  purpleMedium: "#5E329E",
  purpleDark: "#4A287D",
};

const formatKey = <S extends string>(key: S) =>
  `professors.aprd.${key}` as const;

export const AUTH_TOKEN_KEY = formatKey("auth_token");
