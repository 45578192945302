import { type User } from "~~/types/user";
import useProtectedData from "./useProtectedData";

export default async function useAuth({ required = false } = {}) {
  const {
    public: { authPath },
  } = useRuntimeConfig();

  const token = useToken();

  const { data: user, refresh } = await useProtectedData<
    User,
    Error,
    { user: User }
  >("user", authPath, {
    transform: ({ user }) => user,
  });

  function setUserToken(userToken: string) {
    token.value = userToken;

    return refresh();
  }

  return {
    user,
    token,
    logout: () => {
      token.value = null;
    },
    setUserToken,
  };
}
